$av-grey: #a1a2a1;
$av-grey-dark: rgba(0, 0, 0, 0.4);
$av-grey-darker: rgba(0, 0, 0, 0.6);
$av-grey-light: rgba(216, 216, 216, 0.32);
$av-error: #fe4f0c;
$av-red: #ff0027,;
$av-white: #ffffff;
$av-yellow: #f5ac00 ;
$av-green: #09ab08,;
$av-card-border: rgba(242, 242, 242, 1);
$av-row-hover-bg: #f5f5f5;
$av-account-name-clr: #00293f;
$av-filter-title: rgba(0, 41, 63, 0.5);
$av-blue-light: #008fff;
$av-red-dark: rgba(255, 0, 39, 1);
$av-grey-aed-not-active: rgba(161, 162, 161, 1);
$av-grey-aed-at-risk: rgba(245, 172, 0, 1);
$av-progress-bar-fill: #dae2e4;
$av-auth-title: rgba(0, 0, 0, 0.87);
$av-page-title-clr:  #000;
$store_zero_value: rgba(0, 0, 0, 0.38);
$av_table_borders: rgba(0, 0, 0, 0.12);

