/* You can add global styles to this file, and also import other style files */

@import 'node_modules/@angular/flex-layout/_mq.scss';
@import './assets/styles/scss/av_variables.scss';

.red {
  color: $av-red;
}
.mat-mini-fab.mat-danger {
  background-color:$av-red;
}
.mat-mini-fab.mat-success {
  background-color:$av-green ;
}
.mat-mini-fab.mat-risk {
  background-color:$av-yellow ;
}
.mat-mini-fab.mat-unknown {
  background-color:$av-grey ;
}
.green {
  color: $av-green;
}

.yellow {
  color: $av-yellow;
}

.grey {
  color: $av-grey;
}

.success {
  font-size: 14px;
  color: $av-green;
}

.fail {
  font-size: 12px;
  color: $av-red;
}

.warning {
  font-size: 14px;
  color: $av-yellow;
}

.danger {
  font-size: 14px;
  color: $av-red;
}

.grey-shade,
.unknown {
  font-size: 14px;
  color: $av-grey
}

// for mat card
.mat-card {
  box-shadow: none !important;
  font-family: 'ModernEra-Regular' !important;
}

.mat-card-title {
  border-bottom: solid 2px rgba(216, 216, 216, 0.32)
}

// Global button settings

.mat-stroked-button,
.mat-flat-button,
.mat-raised-button,
.mat-button,
.profile-button {
  border-radius: 5px !important;
  font-size: 12px !important;
}

mat-form-field {
  width: 100%;
}

mat-form-field .mat-form-field {
  &-underline {
    position: relative;
  }

  &-subscript-wrapper {
    position: static;
    margin-bottom: -9px;
  }
}

.mat-form-field-outline {
  background: $av-white;
}

// Cards
.av-card,
.av-card-info {
  border-radius: 12px !important;
  border: solid 2px $av-card-border  !important;
  background-color: $av-white  !important;
  margin: 10px 0;
}

.av-card,
.av-card-info {
  mat-card-content {
    font-family: 'ModernEra-Medium';
  }
}

.av-card-info mat-card-content,
app-av-card-content {
  padding-top: 16px;
  padding-bottom: 0px;
  font-size: 14px;

  mat-label {
    color: $av-grey-dark;
  }

  .info {
    padding-bottom: 8px;

    p {
      margin-bottom: 2px;
    }
  }
}

.av-card,
.av-card-info {
  mat-card-actions {
    padding: 0px 8px !important;
    margin-bottom: 0px !important;

    button {
      margin-bottom: 8px;
      margin-left: 0px !important;
    }
  }
}

.av-card-info mat-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 2px rgba(216, 216, 216, 0.32);
  margin: 0px -16px;
  padding-left: 9px;

  .card-title {
    font-size: 14px;
    margin-top: -15px;
    font-weight: bold;
    color: #00293f;
    display: flex;
    padding: 8px;
  }

  button {
    margin-top: -15px;
    align-self: end;
  }
}

.av-card-table {
  background-color: $av-white  !important;
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 12px !important;

}

.info,
.cursor-pointer,
.error-icon {
  cursor: pointer;
}

// For tables
th.mat-header-cell {
  font-size: 14px;
  color: $av-grey-dark  !important;
}

.mat-row:hover {
  background-color: $av-row-hover-bg;
}

table {
  width: 100%;
  border: solid 2px $av-grey-light;
}

table tr:last-child td

/*To remove the last border*/
  {
  border-bottom: 0 solid
}

.mat-table {
  font-family: 'ModernEra-Regular';
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
  width: 100%;
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 0;
}

@media only screen and (max-width: 415px) {
  .mat-table {
    display: block;
    overflow-x: auto !important;
  }
}

th.mat-header-cell {
  font-size: 14px;
  color: $av-grey-dark ;
}

td.mat-cell {
  font-size: 12px;
  color:$av-auth-title;
}
tr.mat-header-row {
  height: 40px !important;
}
td.mat-cell,
th.mat-header-cell {
  padding-right: 10px !important;
  border-bottom-color: solid 2px $av_table_borders  !important;
  word-break: break-word !important;
}

//for mat paginator
.mat-paginator-outer-container {
  border-top: solid 1px $av_table_borders;

  .mat-select-arrow {
    margin-top: 7px !important;
  }

  .mat-paginator-container {
    font-family: 'ModernEra-Regular';
    letter-spacing: 0.25px;
    font-size: 14px;
    padding: 2px;
    min-height: 36px;
    padding-right: 16px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: solid 2px $av-grey-light;
    border-left: solid 2px $av-grey-light;
    border-right: solid 2px $av-grey-light;
    .mat-paginator, .mat-paginator-page-size .mat-select-trigger {
      font-size: 14px;
    }

    .mat-paginator-page-size {
      display: flex;
      align-items: center;
    }

    .mat-paginator-page-size-select {
      margin: 0px;
      height: 35px;
    }
    .mat-paginator-page-size-label {
      margin: 0 12px !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-flex {
      margin-top: -0.3em !important;
      height: 35px !important;
    }
  }

  .mat-form-field-flex .mat-form-field-infix {
    margin-top: -4px !important;
    align-items: center;
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {

      // padding-bottom: 0em;
      .mat-form-field-underline {
        background-color: transparent !important;
      }
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }

  .mat-form-field {
    text-align: center;
  }
}

.egret-navy .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #ffffff;
}

// Side nav
.nav-item-sep {
  height: calc(100vh - 590px);
}

.sidebar-hold {
  height: 100%;
  overflow: hidden;
}

// Tool bar
.topbar {
  font-size: 12px;
  font-family: "ModernEra-Medium";

  .mat-icon-button {
    border-radius: unset !important;
    width: unset !important;
  }

  .notifications {
    width: 79px !important;
    margin-left: 4;

    .mat-icon {
      height: 30px;
      width: 30px
    }
  }

  .profile {
    .username {
      margin-left: 6px;
      max-width: 100px;
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .mat-icon {

      font-size: 24px !important;
      margin-left: -2px;
    }

    .svg-icon {
      width: 32px;
      height: 32px;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.profile-button {
  width: 13% !important;

  img {
    margin-right: 20px;
  }

}

.header {
  top: 0;
  width: 100%;
  height: 64px;
  padding: 0 29px;
  border-bottom: 0.5px solid $av-grey;
  background-color: #fff;

  .logo img {
    width: 120px;
    height: 42.5px;
  }
}

@include layout-bp(lt-md) {
  .header {
    padding: 0 10px;
  }

  .topbar .profile {
    width: 50px;
  }

  .topbar .notifications {
    width: 35px !important;
  }
}

.back-button {
  .material-icons {
    padding-left: 0;
    margin-left: -5px;
  }
}


.account-name {
  padding-left: 15px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: $av-account-name-clr;
  min-width: 160px;
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

// Table filter
.table-container {
  padding-top: 6px;

  app-av-table-wrap {
    background-color: $av-white  !important;
    border-radius: 12px;
  }

  .filters-in {
    padding: 0px 16px 0 16px;
    margin: 20px 0 0 0 !important;
    background: none !important;
  }

  .filters,
  .filters-in {
    display: flex;
    align-items: center;

    .mat-mini-fab {
      margin-bottom: 16px;
      margin-top: 8px;
      min-width: 24px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .material-icons {
      line-height: inherit;
    }
  }
}

// Radio button
.radio-button-group {
  display: flex;
  flex-direction: column;
  margin: 5px 0;

  mat-radio-button {
    margin-bottom: 4px;
  }

  mat-error {
    font-size: 11px;
    margin-left: 8px
  }
}


.av-popup-dlg {
  .top-bar {
    margin-bottom: 16px;
  }
}

.sec-title {
  padding-top: 20px;
  padding-bottom: 10px;

  span {
    display: flex !important;
    align-content: flex-start;
    align-items: center;
  }

  mat-icon {
    margin-left: 4 !important;
  }
}

.map-controls {
  padding-top: 3px;
  position: absolute;
  z-index: 10;
  padding-right: 90px;

  .mat-raised-button {
    border-radius: 18px;
    line-height: 19px !important;
    padding: 0 11px;
    font-size: 10px !important;
    height: 24px;
  }
}


.download-link {
  color: $av-blue-light;
  font-weight: bold;
}

.download-text {
  font-family: 'ModernEra-Medium';
  color: $av-blue-light  !important;
  font-size: 12px;
  width: 100%;
  letter-spacing: 0.99px;
}
.add-hour {
  width: 174px;
  height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #008fff;
  margin-left:5px;
}

.cpr-text {
  padding-top: 1px;
  padding-left: 0px;
  width: 65px;
  height: 14px;
  line-height: 1.4;
  letter-spacing: 0.14px;
  color: #fff;
}

.cpr-button {
  border-radius: 18px !important;
  line-height: 19px !important;
  font-size: 10px !important;
  background-color: $av-account-name-clr  !important;
  min-width: 121px;
  width: 97px;
  height: 23px;
  object-fit: contain;
}

.unhealthy-button {
  border-radius: 18px;
  line-height: 19px !important;
  padding: 0 11px;
  font-size: 10px !important;
  min-width: 121px;
  margin-left: 6px !important;
  height: 24px;
  background-color: $av-red  !important;
}

.healthy-button {
  border-radius: 18px;
  line-height: 19px !important;
  padding: 0 11px;
  font-size: 10px !important;
  min-width: 121px;
  height: 24px;
  margin-left: 6px !important;
  background-color: $av-green  !important;
}

.risk-button {
  border-radius: 18px;
  line-height: 19px !important;
  padding: 0 11px;
  font-size: 10px !important;
  min-width: 121px;
  height: 24px;
  margin-left: 6px !important;
  background-color: $av-yellow  !important;
}

.cpr-button .material-icons {
  margin-left: -14px !important;
  margin-top: 0px !important;
  font-size: 21px !important;
}

.checkbox {
  .cbx-label {
    font-weight: bold;
  }

  label,
  .cbx-label {
    display: inline-flex;

    mat-icon {
      transform: rotate(180deg);
      margin-left: 4px;
    }
  }
}

.error-icon {
  transform: rotate(180deg);
  margin-left: 4px;
  width: 21px !important;
  height: 20px !important;
  font-size: 21px;

}

.success-message {
  border: dotted 0.5px $av-grey-light;
  background-color: $av-grey-light;
  border-radius: 12px;
  padding: 50px 80px;

  p {
    text-align: center;
    padding: 0 4px
  }

  button {
    margin-top: auto;
  }
}

.back-to-login {
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center !important;
  color: $av-grey;
}

.password-message,
.password-message:hover {
  width: 294px !important;
  height: 20px;
  margin-top: 42px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center !important;
  color: $av-grey;
}

.info-buttons {
  margin-left: 4px;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
  word-break: break-word;
}

.mat-chip-list-stacked mat-chip {
  margin-top: 4px;
}

.egret-navy .mat-chip.mat-standard-chip {
  background-color: $av-white  !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid $av-blue-light;
  width: auto !important;

  .mat-icon-button i,
  .mat-icon-button .mat-icon {
    color: $av-grey-darker;
    opacity: unset;
  }

  .mat-chip-remove.mat-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin-left: -5px;
  }
}

mat-chip .mat-icon-button {
  line-height: 0 !important;
  margin-right: -9px !important;
}

.rectangle {
  height: 44px;
  margin: 17px 0px 17px 0px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px $av-card-border;
  background-color: #fff;

  .content,
  .count,
  .count-zero {
    font-family: 'ModernEra-Bold';
    font-stretch: normal;
    font-style: normal;
    text-align: center;
  }

  .content {
    color: $av-auth-title;
  }

  .count {
    color: $av-blue-light  !important;
  }

  .count-zero {
    color: $store_zero_value  !important;
  }
}


.sec-card-title {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.36;
  letter-spacing: 0.25px;
  margin-left: 4px;
  color: $av-account-name-clr;
  align-items: center;
  display: inline-flex;
  font-family: 'ModernEra-Regular';
}


.health-icon {
  color: $av-green;
  font-size: 10px;
  height: 6px !important;
}

a:hover {
  color: $av-grey-darker  !important;
}


//for mat form fields
.egret-navy .mat-form-field-flex>.mat-form-field-infix {
  padding-top: 0.4em !important;
  padding-bottom: 0.7em !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: unset !important;
}

.egret-navy .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.33em) scale(0.75) !important;
  width: auto;
  padding: 0px 5px 0px 0px;
  background: white;
}

.mat-form-field {
  letter-spacing: .1px;
}

.mat-form-field-outline {
  background: #ffffff;
}

.mat-form-field-label-wrapper {
  top: -1.5em;
}


//mat chip
.egret-navy .mat-chip.mat-standard-chip {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #0081ff;
  width: auto !important;

  .mat-icon-button i,
  .mat-icon-button .mat-icon {
    color: #000000DE;
    opacity: unset;
  }

  .mat-chip-remove.mat-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin-left: -5px;
  }
}

mat-chip .mat-icon-button {
  line-height: 0 !important;
  margin-right: -9px !important;
}

.search {
  .mat-icon {
    height: 30px;
    width: 30px
  }
}


// search
egret-search-input-over {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;
  min-width: 150px;
  height: 42px;

  .search-bar {
    .top-search-form {
      position: relative;
      background: #fff;
      border-radius: 40px;
      margin-right: 1rem;
      display: block;
      max-width: 220px;
      margin-left: 20px;
      box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);


      input {
        font-size: 1rem;
        padding: .6rem .75rem;
        z-index: 2;
        cursor: text;
        text-indent: 30px;
        border: none;
        background: transparent;
        width: 100%;
        outline: 0;
      }
    }
  }
}

.egret-navy .mat-progress-bar-buffer {
  background-color: $av-card-border  !important;
}

.session-progress {
  position: relative;
  bottom: 2px;
  z-index: 9999;
  height: 1px !important;
  margin-bottom: 4px;
}

app-av-dialogue-box .session-progress {
  top: -17px;
}

.aed-info {
  padding-top: 16px;
}

.placement-info {
  padding-top: 32px;
}

.header-titles {
  height: 21px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.download-link-normal {
  color: $av-blue-light;
  font-weight: normal;
}

p {
  margin: 0 0 0px;
  letter-spacing: 0.25px;
  line-height: 1.43
}

.cbx-sub {
  display: block;
  margin-left: 25px;
  font-size: 12px;
  font-weight: normal;
  max-width: 340px;
}

.cpr-qstn {
  .mat-flat-button {
    margin: 0 16px 16px 16px;
  }

  .mat-stroked-button {
    margin: 0 16px 16px 0;
  }
}

.toggle-buttons {
  padding-left: 16px;
}

.tbl-health-icon {
  font-size: 10px !important;

  mat-icon {
    height: 10px !important;
  }
}

.w-30 {
  width: 30%;
}

.form-controllers {
  border-radius: 12px !important;
  border: solid 2px rgba(216, 216, 216, 0.32) !important;
  background-color: #fff !important;
  padding: 0px 15px 15px 19px;
}

.time-sep {
  width: 19px;
  height: 24px;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #00293f;

}


.sec-title,
.input-label {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.1px;
  color: $av-auth-title;
  color: $av-account-name-clr;
}

.input-label {
  display: flex !important;
  align-content: flex-start;
  align-items: center;
  mat-icon {
    margin-left: 4px !important;
  }
}

.no-records-found {
  display: flex;
  padding: 10px;
  justify-content: center;
  border-right: solid 2px $av-grey-light;
  border-left: solid 2px $av-grey-light;
}

.capitalize {
  text-transform: capitalize;
}

.c-sentense:first-letter {
  text-transform: capitalize;
}

@include layout-bp(lt-sm) {
  .captch-style {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  .sec-card-title{
    width:105%;
    margin-left: 0px;
  }
  .assign-title-sm{
    margin-top: -8px !important;
  }
  .mat-chip-list-wrapper{
    margin-bottom: 10px !important;
  }
}

@media (max-width: 280px) {
  .captch-style {
    transform: scale(0.69);
    -webkit-transform: scale(0.69);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  .btn-style-cpr {
    padding-right: 19px !important;
  }
  .mat-paginator-range-actions {
    display: contents !important;
    align-items: start !important;
  }
  .account-name {
    font-size: 13px;
  }
  .nofify-style {
    margin-left: -70px !important;
  }
  .assign-title-sm{
    margin-top: -8px !important;
  }
  .mat-radio-label {
    white-space: normal !important;
  }
}


:host ::ng-deep {

  .egret-navy mat-row,
  .egret-navy mat-header-row,
  .egret-navy mat-footer-row,
  .egret-navy th.mat-header-cell,
  .egret-navy td.mat-cell,
  .egret-navy td.mat-footer-cell {
    border-bottom-color: solid $av-grey-light  !important;
  }

  .table-style-2 {
    margin: 0 -18px;
    .mat-paginator {
      margin: 18px -18px -18px -18px;
    }
    .mat-table {
      border-top: none !important;
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }

    .mat-table {
      margin-bottom: -14px !important;
    }
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 15px !important;
  }
}

.map-input,
.map-button {
  border: 0;
  background-color: $av-white;
  overflow: hidden;
  color: $av-account-name-clr  !important;
  font-family: 'ModernEra-Regular' !important;
  font-weight: bold !important;
}

.map-button {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 6px;
  border: solid 2px $av-grey-light;
  box-shadow: 2px 3px 7px -4px rgb(0 0 0);
  font-size: 12px;
  color: $av-account-name-clr;
  height: 28px;
  margin-top: 10px;
}

.map-input {
  margin-right: -2px;
  width: 42%;
  height: 24px;
  margin-top: 12px;
  padding-left: 10px;
  &::placeholder {
    font-size: 9px;
    font-style: italic;
    line-height: 1.78;
    letter-spacing: -0.06px;
    text-align: center;
  }
}

.map-button:hover {
  background: rgb(235, 235, 235);
}

@include layout-bp(lt-md) {
  .map-input {
    width: 25%;
  }
}
@include layout-bp(lt-md) {
  .map-input {
    width: 40%;
  }
}
.cpr-cfn {
  button {
    height: 25px;
    min-width: 50px;
  }
  .mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
    line-height: unset !important;
  }
}
mat-form-field.mat-form-field,
.mat-label {
  .filter-labels {
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .search-labels {
    font-size: 14px !important;
    color: #61666b !important;
  }
}

.filter-title {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: rgba(0, 41, 63, 0.5) !important;
}

.filter-icon {
  font-size: 31px;
  font-weight: bold;
  color: #1790f0 !important;
}
.add-icon {
  font-weight: bold;
  color: #1790f0 !important;
}

.health-circles {
  font-size: 10px;
}

.list-dots {
  display: list-item;
  margin-left: 16px;
}
.aed-section {
  margin-top:16px;
  margin-bottom: 16px;
}

.mat-radio-label-content{
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.mat-radio-inner-circle {
  border-color:#008fff !important;
  background-color: #008fff !important;
}
.mat-radio-outer-circle {
  border-color:#008fff !important;
}
.delete-label {
 letter-spacing: 0.5px;
}
// Set color for radio button and check boxes
::ng-deep {
  .egret-navy {
    .mat-radio-outer-circle,  .mat-checkbox-frame{
      border-color: $av-blue-light !important;
    }
    .mat-radio-inner-circle {
      background-color: $av-blue-light !important;
    }
  }
}
// for all the mat buttons
.mat-button-wrapper {
  letter-spacing: 0.25px !important;
}
.view-incidents {
  font-family: 'ModernEra-Medium';
  color: $av-blue-light  !important;
  font-size: 12px;
  width: 100%;
  letter-spacing: 0.99px;
}

.materror-outside {
  font-family: 'ModernEra-Medium';
  font-size: 10.5px;
}
.view-incidents {
  font-family: 'ModernEra-Medium';
  color: $av-blue-light  !important;
  font-size: 12px;
  width: 100%;
  letter-spacing: 0.99px;
}
app-av-pg-back {
  max-width: 5%;
  .material-icons {
    font-size: 23px !important;
  }
}
// for Dashboard mat tables
.mat-table-db {
  border: none;
}
.text-light-gray {
  color: $store_zero_value;
  letter-spacing: 0.99px;
  font-size: 10px;
}
app-av-pg-back {
  max-width: 5%;
  .material-icons {
    font-size: 23px !important;
  }
}

.order-new-pads {
  font-size: 16px;
  font-family: 'ModernEra-Medium';
  .mat-icon {
    font-size: 24px !important;
    font-weight: bold !important;
  }
}
.mat-calendar-table {
  border: none !important;
}

// Map marker info
.last-sync-info {
  color: $av-grey-darker  !important;
  font-size: 9px;
  font-family: 'ModernEra-Regular';
  font-style: italic;
  line-height: 1.78;
  letter-spacing: -0.06px;
}
button.gm-ui-hover-effect {
  visibility: hidden;
}
.health-tag-info {
  pointer-events: none;
  color: $av-white;
  border: none !important;
  border-radius: 12px;
  margin-right: 8px;
}
.doc-title {
  height: 21px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}
.full-store {
  height: 44px;
  margin: 17px 0 17px 0;
  padding: 13px 9px 12px 15.7px;
  border-radius: 8px;
  border: solid 1px rgba(216, 216, 216, 0.32);
  background-color: #fff;
}
.store-link {
  height: 15px;
  font-family: "ModernEra-Regular";
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #00293f;
}
.docDescription {
  height: 90px;
  }
.location-detail-container {
    height: 420px;
    max-width: 100%;
  }
.location-empty-container {
    height: 385px;
    max-width: 100%;
  }
/* for add location schedule remove*/
.remove-btn {
  margin-bottom: 16px !important
}
.remove-text {
  display: none;
}
@include layout-bp(lt-sm) {
  .remove-btn {
    margin-bottom: 30px !important
  }
  .remove-text {
    display: block;
  }
}

.aed-labels {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}
.aed-sep {
  border-bottom: solid 2px rgba(216, 216, 216, 0.32) !important;
  width:236px;
  padding-top:16px;
  margin-bottom: 16px;
  }
.pay-sep {
  border-right: solid 2px rgba(216, 216, 216, 0.32) !important;
  height: 380px;
}
.payment-box {
  height: 256px;
  margin: 0px 0px 38px 16px;
  border: solid 6px #f323bc;
}
.payment-processor {
  padding-top: 50px;
  font-size: 20px;
  text-align: center;
  color: #f323bc;
  font-weight: 500;
}
