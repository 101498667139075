.topbar {
  &.mat-toolbar {
    position: relative;
    box-shadow: 0 10px 30px 0 rgba(47,60,74,.08);
    z-index: 1001;
  }
  .mat-select-value {
    font-weight: 400;
    color: #ffffff;
  }
}

.toggle-collapsed {
  transform: rotate(0deg);
  -webkit-transition: transform .3s cubic-bezier(.35,0,.25,1);
  transition: transform .3s cubic-bezier(.35,0,.25,1);
}
.sidebar-compact .toggle-collapsed {
  transform: rotate(180deg);
}
egret-search-input-over{
  position: relative;
  top: 0;
  left: 0;
  width: 762px;
  min-width: 150px;
  height: 42px;
  // margin: 0px 69px 4px 94px;
  .search-bar {
    .top-search-form {
      position: relative;
      background: #fff;
      border-radius: 40px;
      margin-right: 1rem;
      display: block;
      max-width: 220px;
      margin-left: 20px;
      box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);

      .material-icons {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
        color: rgba(0,0,0,.87);
      }
      input {
        font-size: 1rem;
        padding: .6rem .75rem;
        z-index: 2;
        cursor: text;
        text-indent: 30px;
        border: none;
        background: transparent;
        width: 100%;
        outline: 0;
      }
    }
  }
}
.mat-divider-border{
  height: 100%;
  border: solid 1px rgba(218, 226, 228, 0.25) !important;
}

