
// COMMON CSS
/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}
.session-form-hold {
  width: 100%;
  max-width: 557px;
  margin: 0 auto;
}
.session-form-hold > .mat-card {
  margin: 0;
}
.session-lockscreen {
  max-width: 320px;
}
.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}
.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: .5rem;
}
.login-error-message {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #fe4f0c;
  font-family: "ModernEra-Medium" !important;
}
.logo-div {
  padding-top: 8px;
  padding-bottom: 16px;
}

.link {
	text-decoration: underline;
}
.link:hover {
	color: #a4adb0;
}
.app-version {
	position: absolute;
	bottom: 0;
	right: 10px;
}

@media  (min-width: 375px) {
  .padding-rl{
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
}
@media  (min-width: 768px) {
  .padding-rl{
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}

.signin-message {
  width: 294px !important;
	height: 20px;
	margin: 42px 15px 12px 15px;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: center !important;
	color: #a4adb0;
}
.forgot-passowrd {
	width: 116px;
	height: 25px;
	font-size: 12px;
	line-height: 2;
	letter-spacing: normal;
	color: #008fff;
}




